const listenForInput = (e) => {
  e.target.parentNode.childNodes.forEach((node) => {
    if (node.className === 'clear') {
      node.style.visibility = 'visible';
    }
  });
  e.target.removeEventListener('input', listenForInput);
};
const clearInputText = (e) => {
  e.target.parentNode.childNodes.forEach((node) => {
    if (node.className === 'searchInput') {
      node.value = '';
      node.addEventListener('input', listenForInput);
    }
  });
  e.target.style.visibility = 'hidden';
};

class AmediaSearchBoxNa extends HTMLElement {
  connectedCallback() {
    if (this.created) {
      return;
    }
    this.querySelector('.clear').addEventListener('click', clearInputText);
    this.querySelector('.searchInput').addEventListener(
      'input',
      listenForInput
    );
    this.created = true;
  }
}

if (customElements && customElements.define) {
  customElements.define('amedia-searchbox-na', AmediaSearchBoxNa);
}
