// Show/hide mini header
// Duplicate header to make miniheader
const naHeader = document.getElementById('page-header');
const insertMiniHeaderBeforeThis =
  document.getElementsByClassName('logo-nav-wrap')[0];
const parentElement = insertMiniHeaderBeforeThis.parentNode;
const naHeaderClone = naHeader.cloneNode(true);
naHeaderClone.removeAttribute('id');
// Find #globalSearchField within naHeaderClone and add the string '-mini' to the id to avoid duplicate id's
const globalSearchField = naHeaderClone.querySelector('#globalSearchField');
globalSearchField.setAttribute('id', 'globalSearchField-mini');
// Add this new id to the label for the search field
const globalSearchLabel = naHeaderClone.querySelector(
  'label[for="globalSearchField"]'
);
globalSearchLabel.setAttribute('for', 'globalSearchField-mini');
naHeaderClone.classList.add('miniheader');
// Find the id am-searchboxSubmit in naHeaderClone and add the string '-mini' to the id to avoid duplicate id's
const searchboxSubmit = naHeaderClone.querySelector('#am-searchboxSubmit');
searchboxSubmit.setAttribute('id', 'am-searchboxSubmit-mini');
// Find the id am-searchboxClear in naHeaderClone and add the string '-mini' to the id to avoid duplicate id's
const searchboxClear = naHeaderClone.querySelector('#am-searchboxClear');
searchboxClear.setAttribute('id', 'am-searchboxClear-mini');
// Add cloned header as miniheader to header
parentElement.insertBefore(naHeaderClone, insertMiniHeaderBeforeThis);

// Hide miniheader from screen readers, and also make it non-tab'able
const miniheaderLinksAndBtns = document.querySelectorAll(
  '.miniheader a, .miniheader button'
);

miniheaderLinksAndBtns.forEach((element) => {
  element.setAttribute('tabindex', '-1');
  element.setAttribute('aria-hidden', 'true');
});

let didScroll;
let lastScrollTop = 0;
const delta = 20;
const miniheaderActivation = 800;

window.addEventListener('scroll', () => {
  didScroll = true;
});

const hasScrolled = () => {
  let st = window.pageYOffset || 0;

  // Make scroll more than delta
  if (Math.abs(lastScrollTop - st) <= delta) return;

  if (st > lastScrollTop) {
    // on scroll DOWN do not show miniheader
    document.querySelector('.miniheader').classList.remove('nav-down');
    document.querySelector('.miniheader').classList.add('nav-up');
  } else if (st > miniheaderActivation) {
    // on scroll UP show miniheader
    document.querySelector('.miniheader').classList.remove('nav-up');
    document.querySelector('.miniheader').classList.add('nav-down');
  }

  lastScrollTop = st;

  // Hide miniheader when close to top
  st = window.pageYOffset || 0;
  if (st < miniheaderActivation) {
    document.querySelector('.miniheader').classList.remove('nav-down');
    document.querySelector('.miniheader').classList.add('nav-up');
  }
};

setInterval(() => {
  if (didScroll) {
    hasScrolled();
    didScroll = false;
  }
}, 200);

document.addEventListener('DOMContentLoaded', () => {
  // Open/close main menu
  const globalMenuBtns = document.querySelectorAll('.global-menu-btn');
  const menuContentLinks = document.querySelectorAll('.menu-content a');
  const menuContentButtons = document.querySelectorAll('.menu-content button');
  const menuContentInput = document.querySelectorAll('.menu-content input');

  // Hide menu from tabbing until activated
  menuContentLinks.forEach((element) => {
    element.setAttribute('tabindex', '-1');
  });
  menuContentButtons.forEach((element) => {
    element.setAttribute('tabindex', '-1');
  });
  menuContentInput.forEach((element) => {
    element.setAttribute('tabindex', '-1');
  });

  globalMenuBtns.forEach((el) => {
    el.addEventListener('click', (e) => {
      if (e.target.nextElementSibling.classList.contains('active-menu')) {
        menuContentLinks.forEach((element) => {
          element.setAttribute('tabindex', '-1');
        });
        menuContentButtons.forEach((element) => {
          element.setAttribute('tabindex', '-1');
        });
        menuContentInput.forEach((element) => {
          element.setAttribute('tabindex', '-1');
        });
        e.target.nextElementSibling.classList.remove('active-menu');
        e.target.nextElementSibling.setAttribute('aria-hidden', 'true');
        e.target.textContent = 'Meny';
        e.target.setAttribute('aria-expanded', 'false');
      } else {
        menuContentLinks.forEach((element) => {
          element.removeAttribute('tabindex');
        });
        menuContentButtons.forEach((element) => {
          element.removeAttribute('tabindex');
        });
        menuContentInput.forEach((element) => {
          element.removeAttribute('tabindex');
        });
        e.target.nextElementSibling.classList.add('active-menu');
        // Remove aria-hidden
        e.target.nextElementSibling.removeAttribute('aria-hidden');
        // Remove tabindex
        e.target.nextElementSibling.removeAttribute('tabindex');
        e.target.textContent = 'Lukk';
        e.target.setAttribute('aria-expanded', 'true');
      }
    });
  });

  // Open/close submenu
  const globalSubMenuBtns = document.querySelectorAll('.global-submenu-btn');
  globalSubMenuBtns.forEach((elem) => {
    elem.addEventListener('click', (e) => {
      if (e.target.nextElementSibling.classList.contains('active-submenu')) {
        e.target.nextElementSibling.classList.remove('active-submenu');
        e.target.setAttribute('aria-expanded', 'false');
      } else {
        e.target.nextElementSibling.classList.add('active-submenu');
        e.target.setAttribute('aria-expanded', 'true');
      }
    });
  });
});
